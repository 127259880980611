'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      {...props}>
      <path
        d="M7.99935 3.04167V5.08333M7.99935 3.04167C7.99935 2.63786 8.11909 2.24313 8.34343 1.90738C8.56777 1.57163 8.88664 1.30994 9.2597 1.15541C9.63277 1.00088 10.0433 0.960453 10.4393 1.03923C10.8354 1.11801 11.1992 1.31246 11.4847 1.59799C11.7702 1.88352 11.9647 2.24731 12.0435 2.64336C12.1222 3.0394 12.0818 3.44991 11.9273 3.82298C11.7727 4.19605 11.5111 4.51491 11.1753 4.73925C10.8396 4.96359 10.4448 5.08333 10.041 5.08333H7.99935M7.99935 3.04167C7.99935 2.63786 7.87961 2.24313 7.65527 1.90738C7.43093 1.57163 7.11206 1.30994 6.739 1.15541C6.36593 1.00088 5.95542 0.960453 5.55937 1.03923C5.16333 1.11801 4.79954 1.31246 4.51401 1.59799C4.22847 1.88352 4.03402 2.24731 3.95525 2.64336C3.87647 3.0394 3.9169 3.44991 4.07143 3.82298C4.22596 4.19605 4.48764 4.51491 4.82339 4.73925C5.15914 4.96359 5.55388 5.08333 5.95768 5.08333H7.99935"
        stroke="#1B2026"
        strokeWidth="1.16667"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.8333 5.08203H2.16667C1.52233 5.08203 1 5.60437 1 6.2487V7.9987C1 8.64303 1.52233 9.16536 2.16667 9.16536H13.8333C14.4777 9.16536 15 8.64303 15 7.9987V6.2487C15 5.60437 14.4777 5.08203 13.8333 5.08203Z"
        stroke="#1B2026"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8327 9.16536V14.4154C13.8327 14.8795 13.6483 15.3246 13.3201 15.6528C12.9919 15.981 12.5468 16.1654 12.0827 16.1654H3.91602C3.45189 16.1654 3.00677 15.981 2.67858 15.6528C2.35039 15.3246 2.16602 14.8795 2.16602 14.4154V9.16536M7.99935 5.08203V16.1654"
        stroke="#1B2026"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Present = styled(Svg)

import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      stroke="black"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.49998 1C4.60996 1 3.73994 1.26392 2.99992 1.75838C2.25991 2.25285 1.68313 2.95565 1.34254 3.77791C1.00195 4.60017 0.912835 5.50497 1.08647 6.37787C1.2601 7.25078 1.68868 8.0526 2.31801 8.68193C2.94735 9.31127 3.74917 9.73985 4.62207 9.91348C5.49498 10.0871 6.39978 9.998 7.22204 9.65741C8.0443 9.31681 8.7471 8.74004 9.24157 8.00002C9.73603 7.26001 9.99995 6.38998 9.99995 5.49997C9.99987 4.30653 9.52575 3.16199 8.68185 2.3181C7.83796 1.4742 6.69342 1.00008 5.49998 1Z"
        stroke="parent"
        strokeWidth="0.825"
        strokeMiterlimit="10"
      />
      <path
        d="M8.85681 8.85742L11.9995 12.0002"
        stroke="parent"
        strokeWidth="0.825"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export const MagnifyingGlass = styled(Svg)
